<template>
  <section>
    <content-header :title="$t('ticketMaintenance')" />
    <div class="container-fluid">
      <Attributes ref="atTicket" :model="model" />
    </div>
  </section>
</template>
<script>
import ContentHeader from '@/components/Header';
import Attributes from './components/Attributes';
import { getTicket as _getTicket } from '@/services/TicketService';

export default {
  name: 'OfficeMaster',
  components: {
    Attributes,
    ContentHeader,
  },
  data() {
    return {
      model: {
        companyId: null,
        showOfficeName: false,
        showQueueName: false,
        showTurnNumber: false,
        showRegistrationDate: false,
        showURL: false,
        showUserName: false,
        showVisitTo: false,
        showLogo: false,
        showMiscellaneous1: false,
        showMiscellaneous2: false,
        showMiscellaneous3: false,
        showMiscellaneous4: false,
        showMiscellaneous5: false,
        showMiscellaneous6: false,
        showMiscellaneous7: false,
      },
    };
  },
  methods: {
    loadTicket(id) {
      if (!id) return;

      _getTicket(id)
        .then(async (response) => {
          const data = response.data;
          this.model = { ...data, logo: null };

          // This was done this way because the preview generation api has a request limit
          // this was moved from a watch to this
          await this.$refs['atTicket'].refreshPreview(this.model);
        })
        .catch((error) => {
          if (error.response.status === 404)
            this.$router.push({ name: 'Tickets' });
        });
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.name === 'TicketEdit') {
          const ticketId = Number(val.params.id);
          this.loadTicket(ticketId);
        } else {
          this.model.ticketId = null;
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .nav,
.nav-pills {
  display: none;
}
.tab-pane {
  display: none;
}
.tab-pane.active.show {
  display: block;
}
</style>
