<template>
  <section class="row">
    <div class="col-md-12">
      <div class="container-fluid px-0">
        <div class="main-page-container">
          <validation-observer ref="form" class="row" novalidate tag="section">
            <div class="col-md-12">
              <div class="row">
                <sub-header :title="$t('ticketMaintenance')" />
              </div>
            </div>

            <div class="col-md-3">
              <div class="row">
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      v-model="model.companyId"
                      fieldtext="text"
                      fieldvalue="value"
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      :label="$t('company')"
                      :options="collections.companies"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      v-model="model.ticketType"
                      fieldtext="text"
                      fieldvalue="value"
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      :label="$t('type')"
                      :options="ticketTypeOptions"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:128"
                  >
                    <base-input
                      v-model="model.ticketName"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('ticketName')"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-12">
                  <custom-image-input
                    v-model="model.logo"
                    accept=".png"
                    :auto-delete="false"
                    :file="model.logoFile"
                    :label="$t('logo')"
                    :max-height="59"
                    :max-width="275"
                    @clearFile="onDeleteImage()"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-2 offset-md-1">
              <div class="row">
                <div class="col-md-12 mt-4">
                  <b-form-checkbox v-model="model.showOfficeName" switch>
                    {{ $t('showOfficeName') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12 mt-2">
                  <b-form-checkbox v-model="model.showQueueName" switch>
                    {{ $t('showQueueName') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12 mt-2">
                  <b-form-checkbox v-model="model.showTurnNumber" switch>
                    {{ $t('showTurnNumber') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12 mt-2">
                  <b-form-checkbox v-model="model.showRegistrationDate" switch>
                    {{ $t('showRegistrationDate') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12 mt-2">
                  <b-form-checkbox v-model="model.showURL" switch>
                    {{ $t('showURL') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12 mt-2">
                  <b-form-checkbox v-model="model.showUserName" switch>
                    {{ $t('showUserName') }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>

            <div class="col-md-2">
              <div class="row">
                <div class="col-md-12 mt-4">
                  <b-form-checkbox v-model="model.showVisitTo" switch>
                    {{ $t('showVisitTo') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12 mt-2">
                  <b-form-checkbox v-model="model.showLogo" switch>
                    {{ $t('showLogo') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12 mt-2">
                  <b-form-checkbox v-model="model.showMiscellaneous1" switch>
                    {{ $t('miscellaneous1') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12 mt-2">
                  <b-form-checkbox v-model="model.showMiscellaneous2" switch>
                    {{ $t('miscellaneous2') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12 mt-2">
                  <b-form-checkbox v-model="model.showMiscellaneous3" switch>
                    {{ $t('miscellaneous3') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12 mt-2">
                  <b-form-checkbox v-model="model.showMiscellaneous4" switch>
                    {{ $t('miscellaneous4') }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>

            <div class="col-md-2">
              <div class="row">
                <div class="col-md-12 mt-4">
                  <b-form-checkbox v-model="model.showMiscellaneous5" switch>
                    {{ $t('miscellaneous5') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12 mt-2">
                  <b-form-checkbox v-model="model.showMiscellaneous6" switch>
                    {{ $t('miscellaneous6') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12 mt-2">
                  <b-form-checkbox v-model="model.showMiscellaneous7" switch>
                    {{ $t('miscellaneous7') }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </validation-observer>
        </div>

        <div class="row">
          <div class="col-12 my-4">
            <h4 class="font-weight-bolder float-left">
              {{ $t('preview') }}
            </h4>
            <hr />
          </div>
        </div>

        <Preview ref="pvTicket" :model="model" :print-enabled="printEnabled" />
      </div>
    </div>
    <div class="col-md-12 my-5 text-right">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'Tickets' })"
        :text="$t('cancel')"
      />
      <button
        class="btn btn-outline-primary"
        variant="outline-primary"
        @click="onSubmit()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </button>
    </div>
  </section>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import BaseInput from '@/components/BaseInput.vue';
import CustomImageInput from '@/components/CustomImageInput';
import Preview from './Preview';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import TicketTypeOptions from '@/constants/TicketTypeOptions';
import {
  submitTicket as _submitTicket,
  deleteImage as _deleteImage,
} from '@/services/TicketService';

export default {
  name: 'TicketAttributes',
  components: {
    SubHeader,
    BaseInput,
    CustomImageInput,
    Preview,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      collections: {
        organizations: [],
        companies: []
      },
      showNotificationModal: false,
      ticketTypeOptions: TicketTypeOptions,
      printEnabled: false
    };
  },
  async mounted() {
    await this.getCompanies();

    this.printEnabled = this.model.ticketId > 0;
  },
  watch: {
    model: {
      immediate: true,
      deep: true,
      handler() {
        this.printEnabled = false;
      },
    },
  },
  methods: {
    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.collections.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit() {
      if (!(await this.$refs.form.validate())) return;

      _submitTicket(this.model)
        .then((response) => {
          if (response.data) {
            this.$router.push({
              name: 'TicketEdit',
              params: { id: response.data },
            });
          }

          this.ShowSuccessSaveToast();

          this.printEnabled = true;

          this.$refs['pvTicket'].refreshPreview(this.model);
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onDeleteImage() {
      if (!this.model.ticketId) {
        this.model.logo = null;
        this.model.logoFile = null;
        return;
      }

      _deleteImage(this.model.ticketId)
        .then(() => {
          this.model.logo = null;
          this.model.logoFile = null;

          this.$refs['pvTicket'].refreshPreview(this.model);

          this.ShowSuccessSaveToast();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async refreshPreview(payload) {
      if(!payload) return null;

      // This was done this way because the preview generation api has a request limit
      // this was moved from a watch to this
      await this.$refs['pvTicket'].refreshPreview(payload);
    }
  },
};
</script>
<style lang="scss" scoped>
h4.font-weight-bolder {
  background-color: $color-app-background;
  padding-right: 10px;
}
</style>
