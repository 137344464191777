<template>
  <div class="px-5">
    <validation-observer ref="createTicketRef" novalidate>
      <b-row>
        <b-col class="d-flex justify-content-center my-4" cols="12">
          <h6 class="text-uppercase">
            {{ $t('assignTicket') }} <b>{{ ticket.ticketName }}</b> {{ $t('toOffices') }}
          </h6>
        </b-col>
      </b-row>
      <b-row>
        <sub-header :title="$t('listOfOffices')" />
      </b-row>
      <b-row class="text-center">
        <div
          v-for="(location, index) in locations"
          :key="index"
          class="col-4 mb-4"
        >
          <p class="m-0">
            {{ location.name }}
          </p>
          <b-form-checkbox
            v-model="location.selected"
            :disabled="location.selected && isOnlyOneSelected"
            size="lg"
            switch
          />
        </div>
        <div class="col-md-12 text-right">
          <base-filled-button
            bg-color="#707070"
            class="float-left mx-0"
            icon-class="far fa-times-circle"
            :on-click="() => $emit('close')"
            :text="$t('cancel')"
          />
          <b-button variant="primary" @click="onSubmit()">
            <i class="fal fa-save" /> {{ $t('save') }}
          </b-button>
        </div>
      </b-row>
    </validation-observer>
  </div>
</template>
<script>
import SubHeader from '@/components/SubHeader.vue';
import BaseFilledButton from '@/components/basics/buttons/BaseFilledButton';
import { assignLocations as _assignLocations , getTicketOffices as _getTicketOffices } from '@/services/TicketService';

export default {
  name: 'AssignOfficesToTicket',
  components: {
    SubHeader,
    BaseFilledButton,
  },
  props: {
    ticket: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      locations: []
    };
  },
  mounted() {
    setTimeout(() => this.getTicketOffices(this.ticket.ticketId), 300);
  },
  computed: {
    isOnlyOneSelected() {
      return this.locations.filter((x) => x.selected).length <= 1;
    },
  },
  methods: {
    async getTicketOffices(ticketId) {
      await _getTicketOffices(ticketId)
        .then((response) => {
          this.locations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit() {
      const payload = {
        companyId: this.ticket.companyId,
        locationConfigurations: this.locations,
        ticketId: this.ticket.ticketId,
      };

      await _assignLocations(payload)
        .then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('on-saved');
          this.$emit('close');
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped>
#ticket-assign-office-modal {
  margin-bottom: 0;
  h6 {
    color: $color-primary;
  }
}
::v-deep label {
  color: $color-font-secondary !important;
  font-size: 20px;
}
::v-deep .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-label:disabled {
  border-color: #ff8105;
  background-color: #ff8105 !important;
}
</style>
