import { apiTurnos } from '@/core/api/';
import { serialize } from 'object-to-formdata';

const qs = require('querystring');

export const getTicket = (id) => apiTurnos.get(`/api/ticket/${id}`);

export const getTicketPreview = (model) => {
  const payload = serialize(model, {
    indices: true,
    nullsAsUndefineds: true,
  });

  return apiTurnos.post('/api/ticket/preview', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getTicketPreviewZPL = (model) => {
  const payload = serialize(model, {
    indices: true,
    nullsAsUndefineds: true,
  });

  return apiTurnos.post('/api/ticket/preview/print', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const assignLocations = (payload) => {
  return apiTurnos.post('api/ticket/location/assign', payload);
};

export const getTicketOffices = (ticketId, getAll = false) => {
  return apiTurnos.get(`api/ticket/${ticketId}/locations`, {
    params: { all: getAll },
  });
};

export const submitTicket = (model) => {
  const payload = serialize(model, {
    indices: true,
    nullsAsUndefineds: true,
  });

  return apiTurnos[model.ticketId ? 'put' : 'post']('/api/ticket', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteTicket = (payload) =>
  apiTurnos.delete(`/api/ticket/${payload.ticketId}`, {
    data: { ...payload },
  });

export const getTickets = (payload) => {
  return apiTurnos.get(`api/ticket/list`, {
    params: {
      ...payload,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const deleteImage = (id) =>
  apiTurnos.delete(`/api/ticket/${id}/image/delete`);
