<template>
  <section>
    <content-header :title="$t('ticketMaintenance')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterTicket')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getTickets(1)"
        />
      </div>
      <separator class="my-4" :text="$t('listTicket')" />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :on-click="() => goToCreate()"
            :text="$t('create')"
          />
        </div>
      </div>
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="(ticket, index) in tickets.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result :value="ticket" @on-edit="onEdit(ticket)" @assign="assignLocation(ticket)" />
        </div>
      </div>
      <custom-pagination
        v-if="tickets.count > 0"
        id="pgPager"
        class="my-4"
        :data="tickets"
        :page-size="pageSize"
        @pagination-go-page="getTickets($event)"
        @pagination-rows-per-page="getTickets(pageIndex, $event)"
      />
    </div>
    <custom-modal v-model="showAssignOfficeModal" size="lg">
      <AssignOfficesToTicket
        v-if="showAssignOfficeModal"
        :ticket="ticket"
        @close="showAssignOfficeModal = false"
        @on-saved="onAssignOfficesToTicketSaved"
      />
    </custom-modal>
    <BigLoader v-if="loading" />
  </section>
</template>

<script>
import CustomPagination from '@/components/CustomPagination';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import SearchResult from './components/SearchResult';
import FilterManager from '@/components/FilterManager';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import { getTickets as _getTickets } from '@/services/TicketService';
import BigLoader from '@/components/BigLoader';
import ContentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import Separator from '@/components/Separator';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import AssignOfficesToTicket from '@/components/AssignOfficesToTicket.vue';

export default {
  name: 'Tickets',
  components: {
    CustomPagination,
    CustomNotFound,
    SearchResult,
    FilterManager,
    ContentHeader,
    SubHeader,
    Separator,
    BigLoader,
    CustomModal,
    AssignOfficesToTicket,
  },
  data() {
    return {
      filters: {
        companies: [],
        location: [],
      },
      selectedFilters: {
        company: [],
        location: [],
      },
      tickets: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      ticket: null,
      pageSize: 10,
      pageIndex: 1,
      showAssignOfficeModal: false,
      noResultsFound: false,
      loading: false,
    };
  },
  mounted() {
    this.getCompanies();
  },
  computed: {
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'company',
          label: this.$t('company'),
          component: 'FilterMultiSelect',
          options: this.filters.companies,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.company,
          onChanged: this.onCompanyChanged,
        },
        {
          rules: '',
          name: 'location',
          label: this.$t('localization'),
          component: 'FilterMultiSelect',
          options: this.filters.location,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.location,
          onChanged: this.onLocationChanged,
        },
      ];
    },
  },
  methods: {
    goToCreate() {
      this.$router.push({ name: 'TicketCreate' });
    },
    assignLocation(ticket) {
      this.ticket = ticket;
      this.showAssignOfficeModal = true;
    },
    onAssignOfficesToTicketSaved() {
      this.getTickets(this.currentPage);
    },
    onEdit(ticket) {
      this.$router.push({
        name: 'TicketEdit',
        params: { id: ticket.ticketId },
      });
    },
    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.filters.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onCompanyChanged(value) {
      this.selectedFilters.location = [];
      this.selectedFilters.company = [];
      this.filters.location = [];

      if (value) {
        this.selectedFilters.company = [value];
        this.getAvailableLocalizations(this.selectedFilters.company[0].value);
      }
    },
    async getAvailableLocalizations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.filters.location = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onLocationChanged(value) {
      if (!value) {
        this.selectedFilters.location = [];
        return;
      }
      this.selectedFilters.location = [value];
    },
    async getTickets(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        CompanyId:
          this.selectedFilters.company.length > 0
            ? this.selectedFilters.company[0].value
            : null,
        LocationConfigurationId:
          this.selectedFilters.location.length > 0
            ? this.selectedFilters.location[0].id
            : null,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getTickets(payload)
        .then(({ data }) => {
          this.tickets = data;
          this.noResultsFound = !this.tickets.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
