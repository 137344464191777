export const print = (turnid, locationConfigurationId) => {
  const printUrl = `${process.env.VUE_APP_URL_API}/api/TurnManager/print?clientPrint&turnId=${turnid}&locationconfigurationid=${locationConfigurationId}`;

  let e_id = 'id_' + new Date().getTime();

  let aEl = document.createElement('a');
  aEl.id = e_id;
  aEl.href = 'webclientprintvi:' + printUrl;
  aEl.click();

  setTimeout(function() {
    const node = document.getElementById(e_id);

    if(node) {
      document.body.removeChild(document.getElementById(e_id));
    }
  }, 5000);
};

export const printTicketPreview = (ticketId) => {
  const printUrl = `${process.env.VUE_APP_URL_API}/api/ticket/preview/print?clientPrint&ticketId=${ticketId}`;

  let e_id = 'id_' + new Date().getTime();

  let aEl = document.createElement('a');
  aEl.id = e_id;
  aEl.href = 'webclientprintvi:' + printUrl;
  aEl.click();

  setTimeout(function() {
    const node = document.getElementById(e_id);

    if(node) {
      document.body.removeChild(document.getElementById(e_id));
    }
  }, 5000);
};