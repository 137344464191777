<template>
  <section class="row">
    <div class="col-md-12">
      <div class="container-fluid px-0">
        <div class="main-page-container">
          <div class="col-md-12">
            <div class="row">
              <sub-header :title="$t('previewView')" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="row justify-content-center">
              <div class="col-sm-12 col-md-8 col-lg-5">
                <div class="ticket-preview">
                  <div class="row">
                    <div class="col-md-12 align-self-center">
                      <img id="logoPreview" :src="previewImage" alt="" />
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-6 text-left">
                      <button
                        class="btn btn-primary"
                        @click="showPreview(model)"
                      >
                        <i class="far fa-redo" /> {{ $t('refresh') }}
                      </button>
                    </div>
                    <div class="col-md-6 text-right">
                      <button
                        class="btn btn-danger"
                        :disabled="!printEnabled"
                        @click="printTicket()"
                      >
                        <i class="fas fa-print" /> {{ $t('print') }}
                      </button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue';
import { getTicketPreview as _getTicketPreview
} from '@/services/TicketService';

import { printTicketPreview as _printTicketPreview } from '@/services/PrintService';

export default {
  name: 'TicketPreview',
  components: {
    SubHeader,
  },
  props: {
    model: {
      type: Object,
      default: () => ({
        ticketId: null,
        logo: null,
        logoFile: null,
        ticketType: null,
        showOfficeName: false,
        showQueueName: false,
        showTurnNumber: false,
        showRegistrationDate: false,
        showURL: false,
        showUserName: false,
        showVisitTo: false,
        showLogo: false,
        showMiscellaneous1: false,
        showMiscellaneous2: false,
        showMiscellaneous3: false,
        showMiscellaneous4: false,
        showMiscellaneous5: false,
        showMiscellaneous6: false,
        showMiscellaneous7: false,
      }),
    },
    printEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      previewImage: null
    };
  },
  methods: {
    async showPreview(payload) {
      await _getTicketPreview(payload).then(({data}) => {
        if (!data.content) return;

        this.previewImage = 'data:image/png;base64,' + data.content;
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async printTicket() {      
      await _printTicketPreview(this.model.ticketId)
        .catch(() => this.ShowErrorToast(this.$t('checkPrinter')));
    },
    async refreshPreview(payload) {
      // This was done this way because the preview generation api has a request limit
      // this was moved from a watch to this
      await this.showPreview(payload);
    }
  },
};
</script>
<style lang="scss" scoped>
.ticket-preview {
  padding: 1em;
  background-color: #ffffff;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

#logoPreview {
  max-width: 100%;
  max-height: 100%;
}
</style>
