<template>
  <div class="p-4 listview card border-light">
    <div class="row">
      <div class="col-sm-12 col-md-3 col-lg-3">
        <div class="cell">
          <label>{{ $t('ticketName') }}: </label>
          <span>{{ value.ticketName || '-' }}</span>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-3">
        <div class="cell">
          <label>{{ $t('company') }}: </label>
          <span>{{ value.companyName || '-' }}</span>
        </div>
      </div>
      <div class="col-sm-12 col-md-2 offset-md-4 col-lg-2 offset-lg-4">
        <action-button :actions="actions" :text="$t('actions')" />
      </div>
    </div>
  </div>
</template>
<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'TicketSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => {
            this.$emit('on-edit');
          },
        },
        {
          label: this.$t('assignOffice'),
          iconClass: 'far fa-plus-square',
          iconColor: '#24852E',
          action: () => this.$emit('assign'),
          visible: () => !!this.value.companyId,
        },
        {
          label: this.$t('delete'),
          iconClass: 'far fa-trash-alt',
          iconColor: '#FF0000',
          action: () => this.$emit('on-delete'),
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.listview .row {
  align-items: center;
}
.listview .cell {
  clear: left;
}
.listview .cell label {
  float: left;
  font-weight: bold;
}
.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}
.card {
  word-break: break-word;
}
.c-actionButton-container {
  margin-top: 0px;
}
</style>
